.tc_login
{
    // width: 100%;
    // height: 100vh;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    // position: fixed;
    padding: 0 15px;
    // padding: 155px 15px 54px;

    &_master
    {
        background-color: $grey-1;
        height: 100%;
        position: relative;
    }

    &_block
    {
        @include centering('custom'){
            top: 50%;
            left: 0;
            @include transform(translate(0, -50%));
        };

        padding: 0 15px;
        width: 100%;
    }

    &_heading
    {
        text-align: center;
        width: 100%;
        border-bottom: 1px solid $grey-7;
        padding: 0 0 12px;
        margin: 0 0 23px;
    }

    .form-item
    {
        label,
        input
        {
            display: block;
            width: 100%;
        }

        label
        {
            color: $grey-4;
        }

        &.form-item-name,
        &.form-item-pass
        {
            margin: 0 0 13px;

            label
            {
                text-transform: uppercase;
                font-size: 10px;
                font-family: $poppins;
                margin: 0 0 7px;
                line-height: 21px;
                letter-spacing: 1.5px;
            }
        }

        &.form-type-checkbox
        {
            label,
            input
            {
                display: inline-block;
                width: auto;
            }

            input
            {
                float: left;
                margin: 20px 0 0;
            }

            label
            {
                letter-spacing: 1.5px;
                color: $grey-2;
                margin: 25px 5px 0;
            }
        }
    }

    .form-actions
    {
        margin: 28px 0 0;

        input
        {
            padding: 5px 15px;
            width: 100%;
        }
    }

    // undisplay
    .form-required,
    .description
    {
        display: none;
    }
}

@include responsive('medium')
{
    .tc_login
    {
        padding: 105px 30px 46px;
        position: fixed;

        &_block
        {
            @include centering('both');
            width: auto;
        }
    }  
}