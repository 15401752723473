.full_screen_slide
{
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;

    .slick-list
    {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .slick-track
    {
        position: relative;
        width: 100%;
        height: 100%;
    }

    // for button navigation
    .slick-arrow
    {
        // removing defult icon
        &::before
        {
            content: none;
        }
    }

    .slick_icon
    {
        @include spriteImg();
        z-index: 1;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-size: 28px;
        height: 57px;
        width: 28px;
        background-position: 0px 0px;

        &-left
        {
          left: 0;
          background-image: url(../images/arrow-left2x.png);
        }

        &-right
        {
          right: 0;
          background-image: url(../images/arrow-right2x.png);
        }

        &:hover,
        &:focus,
        &:active
        {
          background-position: 0px -56px;
        }

        &-rm
        {
          display: none !important;
        }
    }
}

.slick-slide,
.slick-slider .slick-track,
.slick-slider .slick-list
{
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);

  -webkit-perspective: 1000;
  -moz-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  backface-visibility: hidden;
}

@include responsive('medium')
{
    .full_screen_slide
    {
        .slick_icon
        {
            &-rm
            {
                display: block !important;
            }
        }
    }
}