.tc_works
{
    // margin: 175px 0 0;
    width: 100%;
    height: 100%;
    // position: fixed;
    // padding: 0 15px;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    // padding: 0 15px 0;

    &_block
    {
        // width: 100%;
        // height: 100%;
        position: relative;
        padding: 0 15px;
        margin: 0 0 10px;

        &:hover,
        &:focus,
        &:active
        {
            .tc_works_overlay
            {
                opacity: 1;
            }

            .tc_works_image
            {
                @include transform(scale(1.2));
            }
        }

        &:last-child
        {
            margin: 0;
        }
    }

    &_content
    {
        overflow: hidden;
        width: 100%;
        height: 180px;
        // height: 100vh; // for full screen only
        // padding: 0 5px;
        // background-size: cover;
        display: block;
        position: relative;
    }

    &_image
    {
        width: inherit;
        height: inherit;
        @include object-fit(cover);
        @include transition(transform ease-in-out 2s);
        background-image: url('../images/preload.gif');
        background-repeat: no-repeat;
        background-position: center;
        background-size: 24px;
        // @include transition(transform cubic-bezier(0.83, 0.35, 0.43, 0.63) .30s);
    }

    &_overlay
    {
        background-color: rgba($grey-4, 0.75);
        width: inherit;
        height: inherit;
        opacity: 0;
        @include transition(opacity cubic-bezier(0.83, 0.35, 0.43, 0.63) 2s);
        position: absolute;
        top: 0;
    }

    &_heading
    {
        color: $white-1;
        font-size: 16px;
        text-align: center;
        @include centering('vertical');
        text-transform: uppercase;
        margin: 0;
        padding: 0 30px;
        width: 100%;
    }
}

@include responsive('medium')
{
    .tc_works
    {
        // matak
        padding: 105px 25px 46px;
        position: fixed;
        
        &_block
        {
            margin: 0;
            padding: 0 5px;
        }

        &_content
        {
            height: 100%;
            // height: 100vh; // for full screen only
        }
    }
}