.tc_contact
{
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 0 15px;

    // for maps
    &_maps
    {
        position: relative;
        width: 100%;
        height: 50vh;
    }

    // for content
    &_content
    {
        margin: 30px 0 0;
        position: relative;
        width: 100%;
        height: auto;

        &_block
        {
            width: 100%;
        }

        &_heading
        {
            margin: 0 0 33px;
        }

        &_gen{
            width: 100%;
            text-align: center;

            .content
            {
                h4
                {
                    margin: 0 0 5px;
                }

                p
                {
                    margin: 0 0 20px;
                }
                a{
                    text-decoration: none;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
}

#maps_canvas
{
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    background-color: $grey-1;
}

@include responsive('medium')
{
    .tc_contact
    {
        padding: 0;
        // for maps
        &_maps
        {
            height: 45vh;
        }

        // // for content
        &_content
        {
            margin: 0;
            height: 45vh;

            &_block
            {
                height: inherit;
                @include centering('vertical');
            }

            &_gen
            {
                padding: 30px 0;
            }
        }
    }
}

@include responsive('large')
{

}

@include responsive('extra-large')
{
    .tc_contact
    {
        position: fixed;
        padding: 105px 30px 46px;

        // for maps
        &_maps
        {
            height: 100%;
        }

        // // for content
        &_content
        {
            height: 100%;

            &_block
            {
                height: auto;
                width: auto;
                @include centering('both');
            }

            &_gen
            {
                text-align: left;
                padding: 0;
            }
        }
    }
}