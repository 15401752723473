.tc_error
{
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    // position: fixed;
    // padding: 155px 15px 54px;
    padding: 0 15px;

    &_master
    {
        background-color: $grey-1;
        height: 100%;
        position: relative;
    }

    &_block
    {
        @include centering('custom'){
            top: 50%;
            left: 0;
            @include transform(translate(0, -50%));
        };

        padding: 0 15px;
        width: 100%;
        text-align: center;
        letter-spacing: 1.3px;
    }

    &_heading
    {
        font-size: 120px;
        color: rgba($grey-4, .6);
        border-bottom: 1px solid $grey-7;
        margin: 0;
        line-height: 1em;
        padding: 0 0 10px;
    }

    &_subheading
    {
        font-size: 36px;
        color: $grey-4;
        margin: 10px 0 10px;
    }

    &_info
    {
        display: block;
        color: $grey-4;
        margin: 0 0 30px;
    }

    &_link
    {
        background-color: $grey-6;
        color: $white-1;
        text-transform: uppercase;
        padding: 15px 0;
        text-decoration: none;
        min-width: 170px;
        display: inline-block;
        font-size: 12px;

        &:hover,
        &:focus,
        &:active
        {
            background-color: $grey-4;
            color: $white-1;
            text-decoration: none;
        }
    }
}

@include responsive('medium')
{
    .tc_error
    {
        padding: 105px 30px 46px;
        position: fixed;

        &_block
        {
            @include centering('both');
            width: auto;
        }

        &_heading
        {
            font-size: 160px;
        }
    }  
}