// reseting defult
.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover
{
    background-color: transparent;
}

// heading custom
.tc_heading
{
    width: 100%;
    top: 0;
    z-index: 2;

    &_master
    {
        background-color: $white-1;
        border: none;
        padding: 30px 30px 5px;
        @include border-radius(0);
        margin: 0;
    }

    // top menu
    &_logo
    {
        text-align: center;

        &_link
        {
            display: inline-block;
            // width: 114px;
            height: 40px;
        }

        &_img
        {
            width: inherit;
            height: inherit;
        }
    }

    // collapsing
    &_collapse
    {
        // text-align: center;
        display: block;
        border: none;
        padding: 0;
    }

    // general list menu 
    &_list
    {
        margin: 0;
        float: none !important;
        clear: both;
        text-align: center;

        li
        {

            float: none !important;
            display: inline-block !important;
            padding: 0;

            a
            {
                font-family: $poppins;
                text-transform: uppercase;
                text-decoration: none;
                padding: 0;
                margin : 15px 14px;
                letter-spacing: 1.3px;
                color: $grey-3 !important;
                @include transition(.1s color ease-in);
                font-size: 14px;

                &:hover,
                &:focus,
                &:active
                {
                    text-decoration: none;
                    color: $grey-4 !important;
                }
            }
        }

        // dropdown lvl 1
        &_dropdown_1
        {
            &_menu
            {
                background-color: rgba($grey-4, .7);
                box-shadow: none;
                border: none;
                @include border-radius(6px !important);
                padding: 15px 15px 1px;
                right: 0 !important;
                left: 0 !important;
                min-width: 200px;

                li
                {
                    display: block;
                    width: 100%;

                    a
                    {
                        // margin : 8px 0;
                        margin: 0 0 16px !important;

                        &:hover,
                        &:focus,
                        &:active
                        {
                            background-color: transparent;
                            color: $white-1 !important;
                        }
                    }
                }
            }

            &:hover .tc_heading_list_dropdown_1_menu,
            &:focus .tc_heading_list_dropdown_1_menu,
            &:active .tc_heading_list_dropdown_1_menu
            {
                display: block;
            }
        }

        // dropdown lvl 2 (if any)
    }

    // overwrite
    .container-fluid
    {
        padding: 0;
    }
}

.tc_heading_list_dropdown_1_trigger
{
    // text-decoration: underline !important;
}

@include responsive('medium')
{
    .tc_heading
    {
        position: fixed;

        &_master
        {
            padding: 30px 30px 0;
        }

        // top menu
        &_logo
        {
            text-align: left;
            margin: 0;
            margin-bottom: 15px;


            &_link
            {
                height: auto;
                width: 114px;
            }
        }

        // collapsing
        &_collapse
        {
            // text-align: center;
        }

        // general list menu 
        &_list
        {

            float: right !important;
            clear: none;
            text-align: right;

            li
            {

                float: left !important;
                display: block !important;

                &:last-child
                {
                    a
                    {
                        margin: 15px 0 15px 14px;
                    }
                }
            }
        }
    }
}