
// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// variable color
$grey-1: #f6f6f6;
$grey-2: #666666;
$grey-3: #bbbbbb;
$grey-4: #333333;
$grey-5: #A6A6A6;
$grey-6: #AAAAAA;
$grey-7: #dddddd;
$grey-8 : #F5F5F5;
$grey-9: #B4B4B4;
$white-1: #ffffff;
//slick js
$slick-font-path : "../fonts/";
$slick-loader-path : "../images/";

// fonts
@font-face {
    font-family: 'Roboto-Light';
    src: url('../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/Roboto-Light.woff') format('woff'), url('../fonts/Roboto-Light.ttf')  format('truetype'), url('../fonts/Roboto-Light.svg#Roboto-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins-Light';
    src: url('../fonts/Poppins-Light.eot?#iefix') format('embedded-opentype'),  url('../fonts/Poppins-Light.woff') format('woff'), url('../fonts/Poppins-Light.ttf')  format('truetype'), url('../fonts/Poppins-Light.svg#Poppins-Light') format('svg');
    font-weight: normal;
    font-style: normal;
}

$poppins: 'Poppins-Light', sans-serif;
$roboto: 'Roboto-Light', sans-serif;