.page-node{
    main{
        #maps{
            min-height: 100vh;
            height: 100%;
            width: 100%;
        }
        .region{
            .content{
                .field-name-field-sort-order{
                    display: none;
                }
            }            
        }
    }
}

.page-404{
    main{
        .container-fluid{
            background-color: $grey-8;
            height: 100%;
            max-height: 100%;
            display:block;
            position:relative;
            .error-page{
                position: absolute;
                width: 100%;
                top: calc(50% - 100px);
                max-width: 100%;
                left: 0px;
                h1{
                    color: $grey-4;
                    font-size: 160px;
                    line-height: 1em;
                    border-bottom: 1px $grey-7 solid;
                }
                h3{
                    font-size: 36px;
                    color: $grey-4;
                    margin-top: 0px;
                }
                p{
                    font-size: 14px;
                    color: $grey-4;
                }
                a{
                    background-color: $grey-6;
                    color: $white-1;
                    font-size: 12px;
                    border: 0px transparent solid;
                    border-radius: 0px;
                    box-shadow: none;
                    text-decoration: none;
                    padding:10px 25px;
                    &:hover{
                        text-decoration: none;
                    }
                }
            }
        }
    }
}