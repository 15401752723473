.tc_home
{
    // position: relative;
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    // padding: 0 15px 0;
    padding: 125px 15px 54px;
    transition: opacity .8s ease;
    // opacity: 0;

    &_block
    {
        width: 100%;
        height: 100%;
        // position: absolute;
        position: relative;
    }

    &_image
    {
        // width: 100%;
        // height: 100vh; // for full screen only
        @include object-fit(cover);
        width: 100%;
        height: 100%;
    }

    &_content
    {
        position: absolute;
        // bottom: 35px;
        left: 0;
        right: 0;
        // margin-bottom: 50px;
        text-align: center;
    }

    &_gradient
    {
        background: -webkit-linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.39) 100%);
        background: -o-linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.39) 100%);
        background: linear-gradient(rgba(0,0,0,0) 0%, rgba(0,0,0,0.39) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#63000000',GradientType=0 );
        width: 100%;
        bottom: 0;
        position: absolute;
        padding: 0 5px;
    }

    &_heading
    {
        color: $white-1;
        font-size: 14px;
        text-transform: uppercase;
    }
}

@include responsive('medium')
{
    .tc_home
    {
        padding: 105px 30px 46px;

        &_heading
        {
            font-size: 18px;
            margin-bottom: 25px;
        }
    }
}