.tc_detail
{
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0 15px;

    // left part
    &_content
    {
        height: 100%;
        width: 100%;
        float: left;
        position: relative;
        background-color: $grey-1;
        padding: 30px 0 0;
        // margin: 10px 0 40px;
        
        &_master
        {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        &_block
        {
            padding: 0 40px 10px;
        }

        &_heading
        {
            margin: 0 0 10px;
            text-transform: uppercase;
            color: $grey-4;
        }
    }

    &_sosmed
    {
        bottom: 0;
        // position: absolute;
        background: $grey-1;
        width: 100%;
        padding: 25px 40px 25px;
        z-index: 1;

        &-heading
        {
           display: block;
           color: $grey-4;
           margin: 5px 0 5px;
           // padding: 0 10px;
        }

        &-icon
        {
            // hovering action & icon
            transform-origin: 0 0;

            &_fb
            {
                &:hover,
                &:focus,
                &:active
                {
                    @include spritePosition(-147px, -48px, 32px, 32px);   
                }
            }

            &_tw
            {
                &:hover,
                &:focus,
                &:active
                {
                    @include spritePosition(-112px, -45px, 32px, 32px);   
                }
            }

            &_pin
            {
                &:hover,
                &:focus,
                &:active
                {
                    @include spritePosition(-180px, -48px, 32px, 32px);   
                }
            }

            &_env
            {
                &:hover,
                &:focus,
                &:active
                {
                    @include spritePosition(-218px, -46px, 32px, 32px);   
                }
            }
        }
    }

    // right part
    &_slider
    {
        margin: 0 0 15px;
    }

    &_block
    {
        padding: 0 5px;
    }

    &_image
    {
        height: 410px;
        @include object-fit(cover);
    }
}

@include responsive('medium')
{
    .tc_detail
    {
        padding: 0;

        // left part
        &_content
        {            
            height: 29vh;
            padding-bottom: 76px;
        }

        &_sosmed
        {
            position: absolute;
            padding: 5px 40px 5px;
        }

        &_image
        {
            height: 55vh;
        }
    }
}

@include responsive('large')
{
    .tc_detail
    {
        &_content
        {            
            height: 24vh;
        }
    }
}

@include responsive('extra-large')
{
    .tc_detail
    {
        // left part
        &_content
        {
            height: 100%;
            padding-bottom: 0;

            &_block
            {
                padding: 0 40px 108px;
            }

            &_master
            {
                // overflow: auto;
            }

            &_heading
            {
                margin: 30px 0 10px;
            }
        }

        &_sosmed
        {
            // position: absolute;
            padding: 25px 40px 25px;
        }

        &_image
        {
            height: 100%;
        }
    }
}