* { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }

html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

body
{
    font-family: $roboto;
    font-weight: normal;
    color: $grey-2;
    line-height: 1.5em;

    opacity: 0;
    transition: opacity 2s ease;
    -webkit-transition: opacity 2s ease;

    &.noScroll
    {
        overflow: hidden;
    }
}

p
{
    font-family: $roboto;
    font-size: 14px;
    letter-spacing: 1.5px;
}

a
{
    text-decoration: underline;
    color: $grey-3;

    &:hover,
    &:focus,
    &:active
    {
        color: $grey-2;
    }
}

h1, h2, h3, h4, h5, h6
{
    font-family: $poppins;
    letter-spacing: 1.5px;
    line-height: 1.5em;
}

h1
{
    font-size: 36px;
}

h2
{
    font-size: 20px;
}

h3
{
    font-size: 12px;
}

h4, h5, h6
{
    font-size: 10px;
}


input,
textarea,
select
{
    border: 1px solid $grey-5;
    background-color: $white-1;
    min-height: 32px;
    padding: 2px 6px;
    outline: none;

    &:hover,
    &:focus,
    &:active
    {

    }
}

input[type="submit"]
{
    background-color: $grey-6;
    color: $white-1;
    text-transform: uppercase;
    font-size: 12px;
    font-family: $poppins;

    &:hover,
    &:focus,
    &:active
    {  
        background-color: $grey-6 + 10;
    }
}

// scale transformation
@include transformScale(scaleBagi2,.5);
@include transformScale(scaleBagi75,.75);

//sprite image
.sprite
{
    @include spriteImg();
    &_arrow_r_grey {@include spritePosition(-52px, -0px, 96px, 48px)}
    &_arrow_r_black {@include spritePosition(-52px, -104px, 96px, 48px)}
    &_arrow_l_grey {@include spritePosition(-0px, -0px, 96px, 48px)}
    &_arrow_l_black {@include spritePosition(-0px, -104px, 96px, 48px)}
    &_envelope_grey {@include spritePosition(-218px, -6px, 32px, 32px)}
    &_twitter_grey {@include spritePosition(-112px, -4px, 32px, 32px)}
    &_twitter_black {@include spritePosition(-112px, -48px, 32px, 32px)}
    &_pinterest_black {@include spritePosition(-180px, -48px, 32px, 32px)}
    &_pinterest_grey {@include spritePosition(-180px, -6px, 32px, 32px)}
    &_facebook_grey {@include spritePosition(-147px, -6px, 32px, 32px)}
    &_facebook_black {@include spritePosition(-147px, -48px, 32px, 32px)}
    &_envelope_grey {@include spritePosition(-218px, -4px, 32px, 32px)}
    &_envelope_black {@include spritePosition(-218px, -46px, 32px, 32px)}
}

// hide for splashscreen only
.hidebyopacity
{
    // transition: opacity .2s linear;
    opacity: 0;
}

// undisplay
.grippie
{   
    display: none !important;
}

//wrappering
.tc_wrapper_default
{
    // padding: 155px 0 54px;
    position: relative;
    // position: relative;
    width: 100%;
    height: 100%;

    // boga drupal ngagokan
    .region-content
    {
        width: 100%;
        height: 100%;
        
        .block-system
        {
            width: 100%;
            height: 100%;

            .content
            {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.fixed-layout
{
    position: fixed;
}

// for preload image only
.tc_preload
{
    transition: opacity 1s ease;
    -webkit-transition: opacity 1s ease;

    &.slick-initialized
    {
        visibility: visible;
        opacity: 1;
    }
}

// adding class-height to boostrap class
.height-100
{
    height: 100%;
}

// only for 404, login
.only-sia
{
    width: 100%;
    height: 100%;
    min-height: 400px;
}

// heading default
.tc_heading_defult
{
    text-transform: uppercase;
}

@include responsive('medium')
{
    .tc_preload
    {
        opacity: 0;
        visibility: hidden;
    }

    .tc_wrapper_default
    {
        // position: relative;
        padding: 105px 30px 46px;
        // position: absolute;
    }
}

@include responsive('large')
{
    .tc_wrapper_default
    {
        // position: fixed;
    }
}

@include responsive('extra-large')
{
    .tc_wrapper_default
    {
        position: fixed;
    }
}