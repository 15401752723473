//===================================================
//============= css default prefixes ================
//===================================================
@mixin appearance($method)
{
    -webkit-appearance: $method;
    -moz-appearance: $method;
    -ms-appearance: $method;
    -o-appearance: $method;
    appearance: $method;
}

@mixin animation($method)
{
    -webkit-animation: $method;
    -moz-animation: $method;
    -ms-animation: $method;
    -o-animation: $method;
    animation: $method;
}

@mixin border-radius($method)
{
    -webkit-border-radius: $method;
    -moz-border-radius: $method;
    -ms-border-radius: $method;
    -o-border-radius: $method;
    border-radius: $method;
}

@mixin box-shadow($method)
{
    -webkit-box-shadow: $method;
    -moz-box-shadow: $method;
    -ms-box-shadow: $method;
    -o-box-shadow: $method;
    box-shadow: $method;
}

@mixin object-fit($method)
{
    -webkit-object-fit: $method;
    -moz-object-fit: $method;
    -ms-object-fit: $method;
    -o-object-fit: $method;
    object-fit: $method;
}

@mixin placeholder
{
    &.placeholder {@content;}
    &:-moz-placeholder {@content;}
    &::-moz-placeholder {@content;}
    &:-ms-input-placeholder {@content;}
    &::-webkit-input-placeholder {@content;}
}

@mixin transform($method)
{
    -webkit-transform: $method;
    -moz-transform: $method;
    -ms-transform: $method;
    -o-transform: $method;
    transform: $method;
}

@mixin transition($method)
{
    -webkit-transition: $method;
    -moz-transition: $method;
    -ms-transition: $method;
    -o-transition: $method;
    transition: $method;
}

//===================================================
//=================== custom ========================
//===================================================
@mixin centering($position, $position_var: null)
{
    $position_var: absolute !default;
    position: unquote($position_var);

    @if $position == 'vertical'
    {
        top: 50%;
        @include transform(translateY(-50%));
    }
    @else if $position == 'horizontal'
    {   
        left: 50%;
        @include transform(translateX(-50%));
    }
    @else if $position == 'both'
    {
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
    }
    @else if $position == 'custom'
    {

            @content;
       
    }
}

@mixin inline-block()
{
    display: inline-block;
    width: 100%;
}

@mixin keyframes($param)
{
    @-webkit-keyframes #{$param}
    {
        @content;
    }

    @-moz-keyframes #{$param}
    {
        @content;
    }

    @-ms-keyframes #{$param}
    {
        @content;
    }

    @keyframes #{$param}
    {
        @content;
    }
}

@mixin pseudo($content, $display, $pos)
{
    content: $content;
    display: unquote($display);
    position: unquote($pos);
}

@mixin size($width, $height: $width)
{
    width: $width;
    height: $height;
}

@mixin spriteImg()
{
    display: inline-block;
    background-image: url(../images/sprite.png);
    background-repeat: no-repeat;
}

@mixin spritePosition($left, $top, $height, $width)
{
    background-position: $left $top;
    height: $height;
    width: $width;
}

@mixin transformScale($displayName, $scale)
{
    .#{$displayName}
    {
        @include transform(scale($scale))
    }
}

@mixin trim-multiline($row, $Lheight, $font-size)
{
    // trim multiline
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $row;
    overflow: hidden;
    text-overflow: ellipsis;

    // fallback height trim
    line-height: #{$Lheight}em;
    max-height: #{$Lheight*$row*$font-size}px;
    height: #{$Lheight*$row*$font-size}px;
}

// ==================================================================
// ========= Media Query (bootstrap 4 breakpoint method) ============
// ==================================================================
$sm-layout: 576px;
$md-layout: 768px;
$lg-layout: 992px;
$xl-layout: 1200px;

// mobile first method
@mixin responsive($layout)
{
    @if $layout == 'small'
    {
        @media (min-width: $sm-layout)
            {
            @content;
        }
    }
    @else if $layout == 'medium'
    {
        @media (min-width: $md-layout)
            {
            @content;
        }
    }
    @else if $layout == 'large'
    {
        @media (min-width: $lg-layout)
            {
            @content;
        }
    }
    @else if $layout == 'extra-large'
    {
        @media (min-width: $xl-layout)
            {
            @content;
        }
    }
}