#tc_footer
{
    // position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $white-1;
    z-index: 1;
}

.tc_footer
{
    @include inline-block();
    // padding: 10px 0 8px;
    padding: 15px 0 15px;
    text-align: center;
    line-height: 1.5em;

    &_wrapper
    {
        padding: 0 30px;
    }
    

    &_heading
    {
        color: $grey-2;
        margin: 0;
        letter-spacing: 0;
        font-family: $roboto;
    }

    &_link
    {
        text-decoration: none;

        &:hover,
        &:focus,
        &:active
        {
            color: $grey-2;
            // @include transition(.2s color ease-in);
            // text-decoration: none;
        }
    }
}

@include responsive('medium')
{
    #tc_footer
    {
        position: fixed;
    }
}