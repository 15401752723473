.tc_splash
{
	position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transform: scale(0);
    transition: opacity .3s ease, transform .3s ease .3s;
    cursor: pointer;
    
    &_area
    {
    	font-family: $poppins;
    	background-color: rgba($white-1, .95);
    	width: 100%;
    	height: 100%;
    	text-align: center;
    }

    &_block
    {
    	@include centering('both');
    }

    svg
    {
        transform: scale(.7);
    }

    &_iconsvg
    {
        transform: translateY(100%);
        animation: muncul 1.2s forwards;

        $delay: 1;
        @for $i from 1 through 4 {
            &-#{$i} {
                animation-delay: #{($delay * $i)}s;
            }
        }
    }

    &_nav
    {
    	text-align: center;
    	display: block;
    	margin: 0 0 45px;
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
    }

    &_close
    {
        background-image: url('../images/entersprite.png');
        background-position: top;
        background-repeat: no-repeat;
        border:none;
        background-color: transparent;
        background-size: 32px;
        width: 32px;
        height: 32px;
        padding: 0;
        opacity: 0;
        transform: scale(1);
        animation: ada 1.4s forwards 1.6s;

        &:hover,
        &:focus,
        &:active
        {
            cursor: pointer;
            background-position: bottom;
        }
    }

    &-active
    {
    	display: block;
    	opacity: 1;
    	transform: scale(1);
    	animation: ada .6s cubic-bezier(0.74, 0.34, 0.01, 0.24);
    }
}

@include keyframes(muncul)
{
    0%
    {
        transform: translateY(100%);
    }

    100%
    {
        transform: translateY(0);
    }
}

@include keyframes(ada)
{
	0%
	{
		opacity: 0;
        transform:scale(1);
	}

	100%
	{
		opacity: 1;
        transform: scale(1);
	}
}

@include responsive('medium')
{
    .tc_splash
    {
        svg
        {
            transform: scale(1);
        }

        &_nav
        {
            margin: 0 0 65px;
        }
    }
}